import React from 'react';

import { Select, SelectProps } from '@mantine/core';

export const GENDEROPTIONS: Array<string> = [
  'Male',
  'Female',
  'Transgender',
  'Non-binary/Non-conforming',
  'Prefer not to respond',
];

const GenderSelect = ({ ...props }: Omit<SelectProps, 'data'>): JSX.Element => {
  return <Select data={GENDEROPTIONS} placeholder='Select Gender' label='Gender' clearable {...props} />;
};

export default GenderSelect;
