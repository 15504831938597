import React from 'react';

import { Button, Group, Modal, PasswordInput, Stack, TextInput, Title } from '@mantine/core';

interface IEditLoginModalProps {
  opened: boolean;
  closeHandler: () => void;
}

const EditLoginModal = ({ opened, closeHandler }: IEditLoginModalProps): JSX.Element => {
  // const { session } = useAuth();
  // const { successNotification, errorNotification } = notificationHandler();

  // const [isEditingEmail, setIsEditingEmail] = useState(false);
  // const [isEditingPassword, setIsEditingPassword] = useState(false);

  // const emailForm = useForm<FormAccountDetailsValues>({
  //   initialValues: { userId: '', email: '', firstName: '', lastName: '' },

  //   validate: {
  //     firstName: value => nameValidator(value),
  //     lastName: value => nameValidator(value),
  //     email: value => emailValidator(value),
  //   },
  // });

  // const passwordForm = useForm<FormPasswordValues>({
  //   initialValues: { currentPassword: '', newPassword: '', reNewPassword: '' },

  //   validate: {
  //     currentPassword: value => (value ? null : 'Required'),
  //     newPassword: value => (value ? null : 'Required'),
  //     reNewPassword: value => (value ? null : 'Required'),
  //   },
  // });

  // const handlePasswordSubmit = (payload: FormPasswordValues): void => {
  //   changePasswordMutation.mutate(payload, {
  //     onError: (error: any) => {
  //       if (error.response?.data.currentPassword) {
  //         passwordForm.setFieldError('currentPassword', error.response?.data.currentPassword);
  //       } else if (error.response?.data.newPassword) {
  //         passwordForm.setFieldError('newPassword', error.response?.data.newPassword);
  //       } else if (error.response?.data.reNewPassword) {
  //         passwordForm.setFieldError('reNewPassword', error.response?.data.reNewPassword);
  //       } else if (error.response?.data.nonFieldErrors) {
  //         passwordForm.setFieldError('reNewPassword', error.response?.data.nonFieldErrors);
  //       } else {
  //         errorNotification('Password change attempt failed', error.response?.data.message);
  //       }
  //     },
  //     onSuccess: () => {
  //       successNotification('Password was successfully updated');
  //       handleResetPasswordForm();
  //     },
  //   });
  // };

  return (
    <Modal
      opened={opened}
      onClose={closeHandler}
      size='md'
      centered
      title={<Title order={4}>Edit Login</Title>}
    >
      <form>
        <Stack p='md'>
          <TextInput
            // {...usernameForm.getInputProps('email')}
            type='email'
            label='Email'
            placeholder='Enter Email'
            required
          />
          <PasswordInput
            // {...passwordForm.getInputProps('currentPassword')}
            label='Current Password'
            placeholder='Enter Current Password'
            required
          />
          <PasswordInput
            // {...passwordForm.getInputProps('newPassword')}
            label='New Password'
            placeholder='Enter New Password'
            required
          />
          <PasswordInput
            // {...passwordForm.getInputProps('reNewPassword')}
            label='Re-enter New Password'
            placeholder='Re-enter New Password'
            required
          />
        </Stack>

        <Group position='right' pt='md'>
          <Button variant='light' size='sm' radius='md'>
            Cancel
          </Button>
          <Button data-cy='edit-password' type='submit' size='sm' radius='md'>
            Save
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default EditLoginModal;
