import React, { useEffect, useState } from 'react';

import {
  Alert,
  Badge,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Group,
  Loader,
  ScrollArea,
  SegmentedControl,
  Stack,
  Title,
  px,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useFullscreen } from '@mantine/hooks';
import {
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconCalendarEvent,
  IconExclamationCircle,
  IconLogin,
  IconUser,
} from '@tabler/icons-react';
import EmptyState from 'components/EmptyState';
import AthleteAutoComplete, {
  AthleteAutoCompleteValue,
} from 'components/form-fields/auto-complete-fields/AthleteAutoComplete';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import useAthleteHasPastDueBills from 'hooks/athlete/useAthleteHasPastDueBills';
import useDocuments from 'hooks/document/useDocuments';
import useEvents from 'hooks/event/useEvents';
import { Event } from 'types/generated';

import EnterKioskModeModal from './components/EnterKioskModeModal';
import EventCard from './components/EventCard';

dayjs.extend(isBetween);

const Kiosk = ({ isKioskStaffRoute }: { isKioskStaffRoute?: boolean }): JSX.Element => {
  const theme = useMantineTheme();
  const { toggle, fullscreen } = useFullscreen();

  const [isEnterKioskModalOpened, { open: openKioskModal, close: closeKioskModal }] = useDisclosure(false);

  const [events, setEvents] = useState<Event[]>([]);
  const [statusFilter, setStatusFilter] = useState('all');
  const [selectedAthlete, setSelectedAthlete] = useState<AthleteAutoCompleteValue>({
    athleteId: '',
    value: '',
    label: '',
  });

  const startDate = dayjs(new Date());
  const endDate = dayjs(startDate).add(1, 'day');
  const {
    data: athleteEventsData,
    isLoading: isAthleteEventsLoading,
    isSuccess: isAthleteEventsSuccess,
    isError: isAthleteEventsError,
  } = useEvents({
    athlete: selectedAthlete.athleteId,
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  });
  const {
    data: athleteHasPastDueBillsData,
    isLoading: isAthleteHasPastDueBillsLoading,
    isSuccess: isAthleteHasPastDueBillsSuccess,
    isError: isAthleteHasPastDueBillsError,
  } = useAthleteHasPastDueBills(selectedAthlete?.athleteId);

  const {
    data: athleteHasUnsignedDocumentsData,
    isLoading: isAthleteHasUnsignedDocumentsLoading,
    isSuccess: isAthleteHasUnsignedDocumentsSuccess,
    isError: isAthleteHasUnsignedDocumentsError,
  } = useDocuments({
    athleteId: selectedAthlete?.athleteId,
    isSigned: false,
    enabled: !!selectedAthlete?.athleteId,
  });

  const clearAthlete = (): void => {
    setSelectedAthlete({
      athleteId: '',
      value: '',
      label: '',
    });
  };

  useEffect(() => {
    if (
      isAthleteEventsSuccess &&
      athleteEventsData?.data &&
      isAthleteHasPastDueBillsSuccess &&
      athleteHasPastDueBillsData?.data
    ) {
      const nextTwoHoursEvents = athleteEventsData.data.results.filter(event => {
        const eventStartDate = dayjs(`${event.startDate}T${event.startTime}`);
        const eventEndDate = eventStartDate.add(event.duration, 'minutes');

        // Allowing check-in 2 hours before the class start time and 2 hours after the class end time
        const checkInWindowStart = eventStartDate.subtract(2, 'hours');
        const checkInWindowEnd = eventEndDate.add(2, 'hours');

        return dayjs().isBetween(checkInWindowStart, checkInWindowEnd);
      });

      const filterEventsByAthleteStatus = (status: 'isReserved' | 'isWaitlisted'): Event[] => {
        return nextTwoHoursEvents.filter(event =>
          event.eventType === 'appointment_type'
            ? event.athleteAppointments.some(
                athleteClasses =>
                  athleteClasses.athlete.athleteId === selectedAthlete.athleteId &&
                  status === 'isReserved' &&
                  athleteClasses[status],
              )
            : event.athleteKiloClasses.some(
                athleteClasses =>
                  athleteClasses.athlete.athleteId === selectedAthlete.athleteId && athleteClasses[status],
              ),
        );
      };

      const getFilteredEvents = (): Event[] => {
        switch (statusFilter) {
          case 'all':
            return nextTwoHoursEvents;
          case 'reserved':
            return filterEventsByAthleteStatus('isReserved');
          case 'waitlisted':
            return filterEventsByAthleteStatus('isWaitlisted');
          default:
            return nextTwoHoursEvents;
        }
      };

      setEvents(getFilteredEvents());
    } else {
      setEvents([]);
    }
  }, [
    athleteEventsData?.data,
    athleteHasPastDueBillsData?.data,
    isAthleteEventsSuccess,
    isAthleteHasPastDueBillsSuccess,
    selectedAthlete.athleteId,
    statusFilter,
  ]);

  return (
    <>
      <ScrollArea bg='white'>
        <Container py='xl' w='100%'>
          <Stack spacing='xl' py='xl'>
            <Group position='apart' noWrap spacing='md'>
              <Flex direction='column' gap={0}>
                <Title order={1} size={px(theme.spacing.xl) * 2}>
                  Upcoming Events
                </Title>
                <Title order={1} size={theme.spacing.lg} color='dimmed'>
                  Choose an athlete to view upcoming events in next 2 hours
                </Title>
              </Flex>

              {isKioskStaffRoute ? (
                <Button
                  size='xl'
                  color={fullscreen ? 'red' : 'green'}
                  leftIcon={
                    fullscreen ? (
                      <IconArrowsMinimize size={theme.fontSizes.xl} />
                    ) : (
                      <IconArrowsMaximize size={theme.fontSizes.xl} />
                    )
                  }
                  onClick={toggle}
                  data-cy='enterFullscreenModeBtn'
                >
                  {fullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
                </Button>
              ) : (
                <Button
                  leftIcon={<IconLogin size={theme.fontSizes.xl} />}
                  onClick={openKioskModal}
                  data-cy='enterKioskModeBtn'
                  size='xl'
                >
                  Enter Kiosk mode
                </Button>
              )}
            </Group>

            <Divider />

            <AthleteAutoComplete
              placeholder='Select an athlete'
              value={selectedAthlete}
              onChange={athlete =>
                setSelectedAthlete({
                  athleteId: athlete?.athleteId || '',
                  value: athlete?.value || '',
                  label: athlete?.label || '',
                })
              }
              size='xl'
              radius='lg'
              variant='filled'
              data-cy='athleteAutoComplete'
              wrapperProps={{
                'data-cy': 'athleteAutoCompleteWrapper',
              }}
            />

            {isAthleteHasPastDueBillsSuccess && athleteHasPastDueBillsData?.data.hasPastDueBills && (
              <Badge fullWidth color='yellow' size='xl'>
                Attention! This account has a past due bill.
              </Badge>
            )}
            {isAthleteHasUnsignedDocumentsSuccess &&
              athleteHasUnsignedDocumentsData.pages.map(page => page.data.results).flat().length > 0 && (
                <Badge fullWidth color='yellow' size='xl'>
                  Attention! There is an unsigned document on this account.
                </Badge>
              )}

            <SegmentedControl
              value={statusFilter}
              onChange={setStatusFilter}
              data={[
                { label: 'All events', value: 'all' },
                { label: 'Reserved', value: 'reserved' },
                { label: 'In waitlist', value: 'waitlisted' },
              ]}
              size='xl'
              radius='lg'
              color='kiloBlue'
              data-cy='reservationStatusFilter'
            />

            <Stack>
              {(isAthleteEventsError ||
                isAthleteHasPastDueBillsError ||
                isAthleteHasUnsignedDocumentsError) && (
                <Center sx={{ flexGrow: 1 }} p='lg'>
                  <Alert
                    icon={<IconExclamationCircle size={theme.fontSizes.md} />}
                    title='Error'
                    color='red'
                  >
                    Something went wrong while fetching data!
                  </Alert>
                </Center>
              )}

              {(isAthleteEventsLoading ||
                isAthleteHasPastDueBillsLoading ||
                isAthleteHasUnsignedDocumentsLoading) && (
                <Center p='xl'>
                  <Loader />
                </Center>
              )}

              {isAthleteEventsSuccess && events.length === 0 && (
                <Center p='xl'>
                  <EmptyState
                    title='No upcoming events'
                    description={`No upcoming events for ${selectedAthlete.label}`}
                    icon={<IconCalendarEvent size={theme.fontSizes.xl} />}
                  />
                </Center>
              )}

              {!selectedAthlete.athleteId && (
                <Center p='xl'>
                  <EmptyState
                    title='Select an athlete'
                    description='Select an athlete to see upcoming events'
                    icon={<IconUser size={theme.fontSizes.xl} />}
                  />
                </Center>
              )}

              {events.length > 0 && selectedAthlete.label && (
                <Title order={1} color='dark.3'>
                  {`Check-in for ${selectedAthlete.label}`}
                </Title>
              )}

              {events.map(event => (
                <EventCard
                  key={event.eventId}
                  athlete={selectedAthlete}
                  clearAthlete={clearAthlete}
                  event={event}
                />
              ))}
            </Stack>
          </Stack>
        </Container>
      </ScrollArea>

      <EnterKioskModeModal opened={isEnterKioskModalOpened} onClose={closeKioskModal} />
    </>
  );
};

export default Kiosk;
