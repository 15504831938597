import React from 'react';

import { Box } from '@mantine/core';
import { Outlet } from 'react-router-dom';

const PublicLayout = (): JSX.Element => {
  return (
    <Box
      sx={theme => ({
        background: theme.colors.gray[1],
        padding: theme.spacing.xl,
        height: `calc(100vh)`,
        overflow: 'scroll',

        [theme.fn.smallerThan('sm')]: {
          padding: `${theme.spacing.xl} 0`,
        },
      })}
    >
      <Outlet />
    </Box>
  );
};

export default PublicLayout;
