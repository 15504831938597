import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { ErrorMessage } from 'types';
import { client } from 'utils/api-client';

const clearProfile = (): Promise<AxiosResponse<null>> => {
  return client.delete(`auth/clear-profile/`);
};

export default function useClearProfile(): UseMutationResult<AxiosResponse<null>, AxiosError<ErrorMessage>> {
  return useMutation(clearProfile);
}
