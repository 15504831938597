import React from 'react';

import { Button, Group, Text, Title, UnstyledButton, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconUserCircle } from '@tabler/icons-react';
import KiloStaffAccountDetails from 'components/AccountDetails/KiloStaffAccountDetails/KiloStaffAccountDetails';
import StaffAccountDetails from 'components/AccountDetails/StaffAccountDetails/StaffAccountDetails';
import Drawer from 'components/Drawer';
import Menu, { MenuItemProps } from 'components/Menu';
import { useAuth } from 'context/auth-context';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles(theme => ({
  user: {
    display: 'block',
    width: '100%',
    padding: theme.spacing.md,
    color: theme.black,

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));

const UserButton = (): JSX.Element => {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const { session, hasPerm, logout, clearProfile, stopImpersonation } = useAuth();

  const [isAccountDetailsOpen, { open: openAccountDetails, close: closeAccountDetails }] =
    useDisclosure(false);

  const fullName =
    session?.kiloStaff?.fullName || session?.staff?.fullName || session?.athlete?.fullName || '';
  const isImpersonating = session?.isImpersonating;
  const AccountDetails = session?.role === 'kilo_staff' ? KiloStaffAccountDetails : StaffAccountDetails;

  const handleItemClick = (action: string): void => {
    if (action === 'Logout') {
      logout();
    } else if (action === 'Account') {
      openAccountDetails();
    } else if (action === 'Settings') {
      navigate('/gym-settings');
    } else if (action === 'Switch Accounts') {
      clearProfile();
    } else if (action === 'Stop Impersonating') {
      stopImpersonation();
    }
  };

  const getItems = (): MenuItemProps[] => {
    const items: MenuItemProps[] = [
      {
        title: 'Account Details',
        onClick: () => handleItemClick('Account'),
      },
    ];
    if ((session?.role === 'staff' || session?.role === 'owner') && hasPerm('GymMutate')) {
      items.push({
        title: 'Settings',
        onClick: () => handleItemClick('Settings'),
      });
    }

    if (isImpersonating) {
      items.push({
        title: 'Stop Impersonating',
        onClick: () => handleItemClick('Stop Impersonating'),
        divider: 'top',
        color: 'red',
      });
    } else {
      items.push(
        {
          title: 'Switch Accounts',
          onClick: () => handleItemClick('Switch Accounts'),
          divider: 'top',
          color: 'red',
        },
        {
          title: 'Logout',
          onClick: () => handleItemClick('Logout'),
          color: 'red',
        },
      );
    }
    return items;
  };

  return (
    <>
      <Menu
        position='top'
        shadow='md'
        data-cy='userButton'
        target={
          <UnstyledButton className={classes.user}>
            <Group spacing='xs'>
              <IconUserCircle size={theme.fontSizes.xl} />
              <Text size='sm' weight={600}>
                {fullName}
              </Text>
            </Group>
          </UnstyledButton>
        }
        items={getItems()}
      />
      <Drawer
        opened={isAccountDetailsOpen}
        onClose={closeAccountDetails}
        size={600}
        title={<Title order={3}>Account Details</Title>}
        closeOnClickOutside
        footer={
          <Group position='right'>
            <Button variant='subtle' onClick={closeAccountDetails}>
              Close
            </Button>
          </Group>
        }
      >
        <AccountDetails />
      </Drawer>
    </>
  );
};

export default UserButton;
