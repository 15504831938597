import { AxiosError, AxiosResponse } from 'axios';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { ErrorMessage } from 'types';
import { client } from 'utils/api-client';

const updateDefaultProfile = (profileId: string): Promise<AxiosResponse<string>> => {
  return client.put(`profiles/${profileId}/update-default/`, null);
};

export default function useUpdateDefaultProfile(): UseMutationResult<
  AxiosResponse<string>,
  AxiosError<ErrorMessage>,
  string
> {
  const queryClient = useQueryClient();

  return useMutation(updateDefaultProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['profiles']);
    },
  });
}
