import React from 'react';

import { Input } from '@mantine/core';
import { FormErrors } from '@mantine/form';
import { PhoneInput as InternationalPhoneInput, PhoneInputProps } from 'react-international-phone';
import 'react-international-phone/style.css';

interface IPhoneInputProps extends PhoneInputProps {
  label: string;
  error?: FormErrors['value'];
}

const PhoneInput = ({ label, error, ...props }: IPhoneInputProps): JSX.Element => {
  return (
    <Input.Wrapper error={error}>
      <Input.Label>{label}</Input.Label>
      <InternationalPhoneInput {...props} />
    </Input.Wrapper>
  );
};

export default PhoneInput;
