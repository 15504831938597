import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { ErrorMessage } from 'types';
import { client } from 'utils/api-client';

export function logout(): Promise<AxiosResponse<string>> {
  return client.post('/auth/logout/');
}

export default function useLogout(): UseMutationResult<
  AxiosResponse<string>,
  AxiosError<ErrorMessage>,
  null
> {
  return useMutation(logout);
}
