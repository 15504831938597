import React from 'react';

import { Stack } from '@mantine/core';
import ProfilePicture from 'components/ProfilePicture';

import GoogleCalendarIntegrationCard from './components/GoogleCalendarIntegrationCard';
import StaffAccountForm from './components/StaffAccountForm';

const StaffAccountDetails = (): JSX.Element => {
  return (
    <Stack>
      <ProfilePicture />
      <StaffAccountForm />
      <GoogleCalendarIntegrationCard />
    </Stack>
  );
};

export default StaffAccountDetails;
