import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ErrorMessage, Staff, StaffUpdateSelf } from 'types';
import { client } from 'utils/api-client';

const updateSelfStaff = (staff: StaffUpdateSelf): Promise<AxiosResponse<Staff>> => {
  return client.put(`staff/self/`, staff);
};

export default function useUpdateSelfStaff(): UseMutationResult<
  AxiosResponse<Staff>,
  AxiosError<ErrorMessage>,
  StaffUpdateSelf
> {
  const queryClient = useQueryClient();

  return useMutation(updateSelfStaff, {
    onSuccess: () => {
      queryClient.invalidateQueries(['staff']);
    },
  });
}
