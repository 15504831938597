import React from 'react';

import { Group } from '@mantine/core';
import Avatar from 'components/upload/Avatar';
import useUploadProfilePicture from 'hooks/account/useUploadProfilePicture';

const ProfilePicture = (): JSX.Element => {
  const uploadMutation = useUploadProfilePicture();

  // TODO: add various profile picture update logic here

  // const [imgUrl, setImgUrl] = useState<string | null>(user.profilePicture);

  // const handleProfileUpload = (file: File | null): void => {
  //   if (!file) {
  //     return;
  //   }
  //   if (file.size > 5000000) {
  //     errorNotification('The selected file is too large. Please select a file smaller than 5MB.');
  //     return;
  //   }

  //   uploadMutation.mutate(
  //     { userId: user.userId, file },
  //     {
  //       onError: error => {
  //         errorNotification('Profile picture upload failed', error.response?.data.message);
  //       },
  //       onSuccess: res => {
  //         successNotification('Profile picture upload success');
  //         setImgUrl(res.data.url);
  //       },
  //     },
  //   );
  // };

  return (
    <Group position='center'>
      <Avatar
        // imgSrc={imgUrl || user.profilePicture}
        imgSrc={''}
        // handleFileChange={files => handleProfileUpload(files[0])}
        handleFileChange={() => {}}
        loading={uploadMutation.isLoading}
      />
    </Group>
  );
};

export default ProfilePicture;
