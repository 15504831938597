import React from 'react';

import { Stack } from '@mantine/core';
import ProfilePicture from 'components/ProfilePicture';

import KiloStaffAccountForm from './components/KiloStaffAccountForm';

const KiloStaffAccountDetails = (): JSX.Element => {
  return (
    <Stack>
      <ProfilePicture />
      <KiloStaffAccountForm />
    </Stack>
  );
};

export default KiloStaffAccountDetails;
