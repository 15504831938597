import React from 'react';

import { createStyles } from '@mantine/core';
import BannerModal from 'components/banner/BannerModal';
import { Outlet } from 'react-router-dom';
import { INavLinkMobile } from 'routes/types';

import Navbar from './components/Navbar';

const useStyles = createStyles(theme => ({
  layout: {
    height: '100vh',
    width: '100vw',
    maxWidth: theme.breakpoints.sm,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    background: theme.colors.gray[1],
    paddingBottom: '68px',

    '.page': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      flexGrow: 1,

      '.header': {
        width: '100%',
        background: theme.white,
        padding: theme.spacing.md,
        boxShadow: theme.shadows.xs,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing.lg,
        marginBottom: '2px',
      },

      '.content': {
        padding: theme.spacing.md,
        overflowY: 'auto',
        width: '100%',
        flexGrow: 1,
      },

      '.navbar': {
        width: '100vw',
        maxWidth: theme.breakpoints.sm,
        padding: theme.spacing.xs,
        paddingTop: 0,
        position: 'fixed',
        bottom: 0,
      },
    },
  },
}));

const MobileLayout = ({ navLinks }: { navLinks: INavLinkMobile[] }): JSX.Element => {
  const { classes } = useStyles();

  return (
    <div className={classes.layout}>
      <div className='page'>
        <Outlet />
        <Navbar linksData={navLinks} />
        <BannerModal />
      </div>
    </div>
  );
};

export default MobileLayout;
