import React, { useContext, createContext, useState, useCallback, useEffect } from 'react';

import { AxiosResponse } from 'axios';
import useClearProfile from 'hooks/auth/useClearProfile';
import useImpersonate from 'hooks/auth/useImpersonate';
import useLogin from 'hooks/auth/useLogin';
import useLogout from 'hooks/auth/useLogout';
import useSelectProfile from 'hooks/auth/useSelectProfile';
import useStopImpersonation from 'hooks/auth/useStopImpersonation';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Login, Session } from 'types';
import { client } from 'utils/api-client';
import notificationHandler from 'utils/notifications';

interface ProviderProps {
  session: Session | null;
  isLoading: boolean;
  isLoginLoading: boolean;
  login: (payload: Login) => void;
  logout: () => void;
  selectProfile: (profileId: string) => void;
  clearProfile: () => void;
  impersonate: (profileId: string) => void;
  stopImpersonation: () => void;
  hasPerm: (permission: string) => boolean;
}

const AuthContext = createContext<ProviderProps>({
  session: null,
  isLoading: false,
  isLoginLoading: false,
  login: () => {},
  logout: () => {},
  selectProfile: () => {},
  clearProfile: () => {},
  impersonate: () => {},
  stopImpersonation: () => {},
  hasPerm: () => false,
});

const AuthProvider = ({ children }: { children: React.ReactNode }): any => {
  const queryClient = useQueryClient();
  const { errorNotification } = notificationHandler();
  const loginMutation = useLogin();
  const logoutMutation = useLogout();
  const selectProfileMutation = useSelectProfile();
  const clearProfileMutation = useClearProfile();
  const impersonationMutation = useImpersonate();
  const stopImpersonationMutation = useStopImpersonation();
  const navigate = useNavigate();

  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const whoAmI = useCallback(() => {
    setIsLoading(true);
    client
      .get('/auth/session/')
      .then((response: AxiosResponse<Session>) => {
        setSession(response.data);
      })
      .catch(() => {
        setSession(null);
        queryClient.clear();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [queryClient]);

  const login = useCallback(
    ({ email, password }: Login & { navigateAfterLogin?: boolean }): void => {
      loginMutation.mutate(
        { email, password },
        {
          onSuccess: () => {
            whoAmI();
          },
          onError: error => {
            errorNotification('Login failed', error.response?.data.message);
          },
        },
      );
    },
    [errorNotification, loginMutation, whoAmI],
  );

  const logout = useCallback((): void => {
    logoutMutation.mutate(null, {
      onSuccess: () => {
        queryClient.clear();
        setSession(null);
        navigate('/login');
      },
      onError: () => {
        errorNotification('Logout failed');
        whoAmI();
      },
    });
  }, [errorNotification, logoutMutation, navigate, queryClient, whoAmI]);

  const selectProfile = useCallback(
    (profileId: string): void => {
      selectProfileMutation.mutate(
        { profileId },
        {
          onError: res => {
            errorNotification('Profile selection failed', res.response?.data.message);
          },
          onSettled: () => {
            whoAmI();
          },
        },
      );
    },
    [errorNotification, selectProfileMutation, whoAmI],
  );

  const clearProfile = useCallback((): void => {
    clearProfileMutation.mutate(null, {
      onSuccess: () => {
        queryClient.clear();
      },
      onError: () => {
        errorNotification('Something failed');
      },
      onSettled: () => {
        whoAmI();
      },
    });
  }, [clearProfileMutation, errorNotification, queryClient, whoAmI]);

  const impersonate = useCallback(
    (profileId: string): void => {
      impersonationMutation.mutate(
        { profileId },
        {
          onSuccess: () => {
            queryClient.clear();
          },
          onError: res => {
            errorNotification('Impersonation failed', res.response?.data.message);
          },
          onSettled: () => {
            whoAmI();
          },
        },
      );
    },
    [errorNotification, impersonationMutation, queryClient, whoAmI],
  );

  const stopImpersonation = useCallback((): void => {
    stopImpersonationMutation.mutate(null, {
      onSuccess: () => {
        queryClient.clear();
      },
      onError: () => {
        errorNotification('Something failed');
      },
      onSettled: () => {
        whoAmI();
      },
    });
  }, [stopImpersonationMutation, errorNotification, queryClient, whoAmI]);

  const hasPerm = useCallback(
    (permission: string): boolean => {
      if (!session) return false;
      if (session.athlete) return true;
      if (session.staff) {
        if (session.role === 'owner') return true;
        return session.staff.permissions.includes(permission);
      }
      if (session.kiloStaff) return true;
      return false;
    },
    [session],
  );

  useEffect(() => {
    whoAmI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        session,
        isLoading,
        isLoginLoading: loginMutation.isLoading,
        login,
        logout,
        hasPerm,
        selectProfile,
        clearProfile,
        impersonate,
        stopImpersonation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): ProviderProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export default AuthProvider;
