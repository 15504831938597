import React from 'react';

import { IconCalendar, IconUserCircle, IconArchive, IconSettings, IconDashboard } from '@tabler/icons-react';
import DesktopLayout from 'layouts/DesktopLayout';
import MobileLayout from 'layouts/MobileLayout';
import { Navigate } from 'react-router-dom';
import { loadingRouteMap } from 'routes';
import { SessionGym } from 'types';

import { INavLink, IRouteMap } from './types';

export const staffRouteMap: IRouteMap = {
  layout: DesktopLayout,
  routes: [
    {
      key: 'dashboard',
      component: React.lazy(() => import('pages/private/owner/Dashboard')),
      path: '/',
    },
    {
      key: 'arm',
      component: React.lazy(() => import('pages/private/owner/reports/ArmReport')),
      path: '/reports/arm/:type',
    },
    {
      key: 'total-revenue',
      component: React.lazy(() => import('pages/private/owner/reports/TotalRevenue')),
      path: '/reports/total-revenue',
    },
    {
      key: 'upcoming-revenue',
      component: React.lazy(() => import('pages/private/owner/reports/UpcomingRevenue')),
      path: '/reports/upcoming-revenue',
    },
    {
      key: 'pending-payments',
      component: React.lazy(() => import('pages/private/owner/reports/PendingPayments')),
      path: '/reports/pending-payments',
    },
    {
      key: 'past-due-payments',
      component: React.lazy(() => import('pages/private/owner/reports/PastDuePayments')),
      path: '/reports/past-due-payments',
    },
    {
      key: 'total-members',
      component: React.lazy(() => import('pages/private/owner/reports/TotalMembers')),
      path: '/reports/total-members',
    },
    {
      key: 'paying-members',
      component: React.lazy(() => import('pages/private/owner/reports/PayingMembers')),
      path: '/reports/paying-members',
    },
    {
      key: 'new-members',
      component: React.lazy(() => import('pages/private/owner/reports/NewMembers')),
      path: '/reports/new-members',
    },
    {
      key: 'cancelled-members',
      component: React.lazy(() => import('pages/private/owner/reports/CancelledMembers')),
      path: '/reports/cancelled-members',
    },
    {
      key: 'missing-members',
      component: React.lazy(() => import('pages/private/owner/reports/MissingMembers')),
      path: '/reports/missing-members',
    },
    {
      key: 'reactivated-members',
      component: React.lazy(() => import('pages/private/owner/reports/ReactivatedMembers')),
      path: '/reports/reactivated-members',
    },
    {
      key: 'trial-members',
      component: React.lazy(() => import('pages/private/owner/reports/TrialMembers')),
      path: '/reports/trial-members',
    },
    {
      key: 'cancelled-trial-members',
      component: React.lazy(() => import('pages/private/owner/reports/CancelledTrialMembers')),
      path: '/reports/cancelled-trial-members',
    },
    {
      key: 'athletes-on-hold',
      component: React.lazy(() => import('pages/private/owner/reports/AthletesOnHold')),
      path: '/reports/athletes-on-hold',
    },
    {
      key: 'athletes-at-risk',
      component: React.lazy(() => import('pages/private/owner/reports/AthletesAtRisk')),
      path: '/reports/athletes-at-risk',
    },
    {
      key: 'athlete-attendance',
      component: React.lazy(() => import('pages/private/owner/reports/AthleteAttendance')),
      path: '/reports/athlete-attendance',
    },
    {
      key: 'athlete-birthdays',
      component: React.lazy(() => import('pages/private/owner/reports/AthleteBirthdays')),
      path: '/reports/athlete-birthdays',
    },
    {
      key: 'package-revenue',
      component: React.lazy(() => import('pages/private/owner/reports/PackageRevenue')),
      path: '/reports/package-revenue',
    },
    {
      key: 'package-category-revenue',
      component: React.lazy(() => import('pages/private/owner/reports/PackageCategoryRevenue')),
      path: '/reports/package-category-revenue',
    },
    {
      key: 'kiosk',
      component: React.lazy(() => import('pages/private/owner/Kiosk')),
      path: '/kiosk',
    },
    {
      key: 'scheduling',
      component: React.lazy(() => import('pages/private/owner/Scheduling')),
      path: '/calendar/scheduling',
    },
    {
      key: 'programming',
      component: React.lazy(() => import('pages/private/owner/Program')),
      path: '/calendar/programming',
    },
    {
      key: 'packages',
      component: React.lazy(() => import('pages/private/owner/Package')),
      path: '/products/packages',
    },
    {
      key: 'retail',
      component: React.lazy(() => import('pages/private/owner/Retail')),
      path: '/products/retail',
    },
    {
      key: 'fees-and-misc',
      component: React.lazy(() => import('pages/private/owner/FeesAndMiscProducts')),
      path: '/products/fees-and-misc-products',
    },
    {
      key: 'tax-rates',
      component: React.lazy(() => import('pages/private/owner/TaxRates')),
      path: '/products/tax-rates',
    },
    {
      key: 'sales-portal',
      component: React.lazy(() => import('pages/private/owner/SalesPortalConfiguration')),
      path: '/products/sales-portal',
    },
    {
      key: 'athletes',
      component: React.lazy(() => import('pages/private/owner/Athletes')),
      path: '/users/athletes',
    },
    {
      key: 'athlete-detail',
      component: React.lazy(() => import('pages/private/owner/AthleteDetail')),
      path: '/users/athletes/:athleteId/:activeTab?',
    },
    {
      key: 'staff',
      component: React.lazy(() => import('pages/private/owner/Staff')),
      path: '/users/staff',
    },
    {
      key: 'classes',
      component: React.lazy(() => import('pages/private/owner/ClassType')),
      path: '/events/classes',
    },
    {
      key: 'appointments',
      component: React.lazy(() => import('pages/private/owner/AppointmentType')),
      path: '/events/appointments',
    },
    {
      key: 'location',
      component: React.lazy(() => import('pages/private/owner/Location')),
      path: '/events/location',
    },
    {
      key: 'issued-documents',
      component: React.lazy(() => import('pages/private/owner/IssuedDocuments')),
      path: '/documents/issued',
    },
    {
      key: 'document-templates',
      component: React.lazy(() => import('pages/private/owner/DocumentTemplates')),
      path: '/documents/templates',
    },
    {
      key: 'invoices',
      component: React.lazy(() => import('pages/private/owner/Invoice')),
      path: '/invoices',
    },
    {
      key: 'payroll-report',
      component: React.lazy(() => import('pages/private/owner/PayrollReport')),
      path: '/payroll/payroll-report',
    },
    {
      key: 'pay-rates',
      component: React.lazy(() => import('pages/private/owner/PayRates')),
      path: '/payroll/pay-rates',
    },
    {
      key: 'assign-rates',
      component: React.lazy(() => import('pages/private/owner/AssignRates')),
      path: '/payroll/assign-rates',
    },
    {
      key: 'flows',
      component: React.lazy(() => import('pages/private/owner/Flows')),
      path: 'flows',
    },
    {
      key: 'flow-builder',
      component: React.lazy(() => import('pages/private/owner/FlowBuilder')),
      path: 'flows/builder',
    },
    {
      key: 'flow-builder-edit',
      component: React.lazy(() => import('pages/private/owner/FlowBuilder')),
      path: '/flows/builder/:flowId',
    },
    {
      key: 'flow-templates',
      component: React.lazy(() => import('pages/private/owner/NotificationMessageTemplates')),
      path: '/flows/templates',
    },
    {
      key: 'log-work',
      component: React.lazy(() => import('pages/private/owner/LogWork')),
      path: '/log-work',
    },
    {
      key: 'gym-settings',
      component: React.lazy(() => import('pages/private/owner/GymSettings')),
      path: '/gym-settings',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/' }),
      path: '*',
    },
  ],
  navLinks: [
    { key: 'dashboard', label: 'Dashboard', route: '/' },
    { key: 'kiosk', label: 'Kiosk', route: '/kiosk' },
    {
      key: 'calendar',
      label: 'Calendar',
      route: '/calendar',
      subLinks: [
        { key: 'scheduling', label: 'Scheduling', route: '/calendar/scheduling' },
        { key: 'programming', label: 'Programming', route: '/calendar/programming' },
      ],
    },
    {
      key: 'products',
      label: 'Products',
      route: '/products',
      subLinks: [
        { key: 'packages', label: 'Packages', route: '/products/packages' },
        { key: 'retail', label: 'Retail', route: '/products/retail' },
        {
          key: 'fees-and-misc',
          label: 'Fees & Misc Products',
          route: '/products/fees-and-misc-products',
        },
        { key: 'tax-rates', label: 'Tax Rates', route: '/products/tax-rates' },
        {
          key: 'sales-portal',
          label: 'Sales Portal',
          route: '/products/sales-portal',
        },
      ],
    },
    {
      key: 'users',
      label: 'Users',
      route: '/users',
      subLinks: [
        { key: 'athletes', label: 'Athletes', route: '/users/athletes' },
        { key: 'staff', label: 'Staff', route: '/users/staff' },
      ],
    },
    {
      key: 'events',
      label: 'Events',
      route: '/events',
      subLinks: [
        { key: 'classes', label: 'Classes', route: '/events/classes' },
        { key: 'appointments', label: 'Appointments', route: '/events/appointments' },
        { key: 'location', label: 'Locations', route: '/events/location' },
      ],
    },
    {
      key: 'documents',
      label: 'Documents',
      route: '/documents',
      subLinks: [
        { key: 'issued-documents', label: 'Issued', route: '/documents/issued' },
        { key: 'document-templates', label: 'Templates', route: '/documents/templates' },
      ],
    },
    { key: 'invoices', label: 'Invoices', route: '/invoices' },
    {
      key: 'payroll',
      label: 'Payroll',
      route: '/payroll',
      subLinks: [
        { key: 'payroll-report', label: 'Payroll Report', route: '/payroll/payroll-report' },
        { key: 'pay-rates', label: 'Pay Rates', route: '/payroll/pay-rates' },
        { key: 'assign-rates', label: 'Assign Rates', route: '/payroll/assign-rates' },
      ],
    },
    {
      key: 'flows',
      label: 'Flows',
      route: '/flows',
    },
    { key: 'log-work', label: 'Log Work', route: '/log-work' },
  ],
};

export const staffMobileRouteMap: IRouteMap = {
  layout: MobileLayout,
  routes: [
    {
      key: 'scheduling',
      component: React.lazy(() => import('pages/private/ownerMobile/Schedule')),
      path: '/',
    },
    {
      key: 'athletes',
      component: React.lazy(() => import('pages/private/ownerMobile/Athletes')),
      path: '/athletes',
    },
    {
      key: 'athlete-detail',
      component: React.lazy(() => import('pages/private/ownerMobile/AthleteDetail')),
      path: '/athletes/:athleteId/:activeTab?',
    },
    {
      key: 'retail-fees',
      component: React.lazy(() => import('pages/private/ownerMobile/RetailAndMiscFees')),
      path: '/retail-fees',
    },
    {
      key: 'dashboard',
      component: React.lazy(() => import('pages/private/ownerMobile/Dashboard')),
      path: '/dashboard',
    },
    {
      key: 'arm',
      component: React.lazy(() => import('pages/private/owner/reports/ArmReport')),
      path: '/reports/arm/:type',
    },
    {
      key: 'total-revenue',
      component: React.lazy(() => import('pages/private/owner/reports/TotalRevenue')),
      path: '/reports/total-revenue',
    },
    {
      key: 'upcoming-revenue',
      component: React.lazy(() => import('pages/private/owner/reports/UpcomingRevenue')),
      path: '/reports/upcoming-revenue',
    },
    {
      key: 'pending-payments',
      component: React.lazy(() => import('pages/private/owner/reports/PendingPayments')),
      path: '/reports/pending-payments',
    },
    {
      key: 'past-due-payments',
      component: React.lazy(() => import('pages/private/owner/reports/PastDuePayments')),
      path: '/reports/past-due-payments',
    },
    {
      key: 'total-members',
      component: React.lazy(() => import('pages/private/owner/reports/TotalMembers')),
      path: '/reports/total-members',
    },
    {
      key: 'paying-members',
      component: React.lazy(() => import('pages/private/owner/reports/PayingMembers')),
      path: '/reports/paying-members',
    },
    {
      key: 'new-members',
      component: React.lazy(() => import('pages/private/owner/reports/NewMembers')),
      path: '/reports/new-members',
    },
    {
      key: 'cancelled-members',
      component: React.lazy(() => import('pages/private/owner/reports/CancelledMembers')),
      path: '/reports/cancelled-members',
    },
    {
      key: 'missing-members',
      component: React.lazy(() => import('pages/private/owner/reports/MissingMembers')),
      path: '/reports/missing-members',
    },
    {
      key: 'reactivated-members',
      component: React.lazy(() => import('pages/private/owner/reports/ReactivatedMembers')),
      path: '/reports/reactivated-members',
    },
    {
      key: 'trial-members',
      component: React.lazy(() => import('pages/private/owner/reports/TrialMembers')),
      path: '/reports/trial-members',
    },
    {
      key: 'cancelled-trial-members',
      component: React.lazy(() => import('pages/private/owner/reports/CancelledTrialMembers')),
      path: '/reports/cancelled-trial-members',
    },
    {
      key: 'athletes-on-hold',
      component: React.lazy(() => import('pages/private/owner/reports/AthletesOnHold')),
      path: '/reports/athletes-on-hold',
    },
    {
      key: 'athletes-at-risk',
      component: React.lazy(() => import('pages/private/owner/reports/AthletesAtRisk')),
      path: '/reports/athletes-at-risk',
    },
    {
      key: 'athlete-attendance',
      component: React.lazy(() => import('pages/private/owner/reports/AthleteAttendance')),
      path: '/reports/athlete-attendance',
    },
    {
      key: 'athlete-birthdays',
      component: React.lazy(() => import('pages/private/owner/reports/AthleteBirthdays')),
      path: '/reports/athlete-birthdays',
    },
    {
      key: 'account',
      component: React.lazy(() => import('pages/private/ownerMobile/AccountDetails')),
      path: '/account',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/' }),
      path: '*',
    },
  ],
  navLinks: [
    {
      key: 'dashboard',
      Icon: IconDashboard,
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      key: 'athletes',
      Icon: IconUserCircle,
      label: 'Athletes',
      route: '/athletes',
    },
    {
      key: 'schedule',
      Icon: IconCalendar,
      label: 'Schedule',
      route: '/',
    },
    {
      key: 'retail-fees',
      Icon: IconArchive,
      label: 'Retail/Fees',
      route: '/retail-fees',
    },
    {
      key: 'account',
      Icon: IconSettings,
      label: 'Account',
      route: '/account',
    },
  ],
};

const customConnectStaffRouteMap: IRouteMap = {
  layout: staffRouteMap.layout,
  routes: [
    ...staffRouteMap.routes,
    {
      key: 'stripe-dashboard',
      component: React.lazy(() => import('pages/private/owner/StripeDashboard')),
      path: '/stripe-dashboard',
    },
  ],
  navLinks: [
    ...staffRouteMap.navLinks,
    {
      key: 'stripe-dashboard',
      label: 'Stripe Dashboard',
      route: '/stripe-dashboard',
    },
  ],
};

export const onboardingRouteMap: IRouteMap = {
  layout: DesktopLayout,
  routes: [
    {
      key: 'onboarding',
      component: React.lazy(() => import('pages/private/owner/OnboardingPage')),
      path: '/',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/' }),
      path: '*',
    },
  ],
  navLinks: [
    {
      key: 'onboarding',
      label: 'Onboarding',
      route: '/',
    },
  ],
};

const routeKeyToPermissionMap: { [key: string]: string | null } = {
  dashboard: null,
  arm: 'BusinessMetricsReportsView',
  'total-revenue': 'AccountingReportsView',
  'upcoming-revenue': 'AccountingReportsView',
  'pending-payments': 'PaymentReportsView',
  'past-due-payments': 'PaymentReportsView',
  'total-members': 'AthleteReportsView',
  'paying-members': 'AthleteReportsView',
  'new-members': 'AthleteReportsView',
  'cancelled-members': 'AthleteReportsView',
  'missing-members': 'AthleteReportsView',
  'reactivated-members': 'AthleteReportsView',
  'trial-members': 'AthleteReportsView',
  'cancelled-trial-members': 'AthleteReportsView',
  'athletes-on-hold': 'AthleteReportsView',
  'athletes-at-risk': 'AthleteReportsView',
  'athlete-attendance': null,
  'athlete-birthdays': null,
  'package-revenue': 'PackageReportsView',
  'package-category-revenue': 'PackageReportsView',
  kiosk: 'Kiosk',
  scheduling: null,
  programming: null,
  packages: null,
  retail: null,
  'fees-and-misc': null,
  'tax-rates': null,
  'sales-portal': null,
  athletes: 'AthleteView',
  'athlete-detail': 'AthleteView',
  staff: 'StaffView',
  classes: null,
  appointments: null,
  location: null,
  'issued-documents': null,
  'document-templates': null,
  invoices: 'InvoiceReportsView',
  'payroll-report': 'PayrollReportView',
  'pay-rates': 'PayrollMutate',
  'assign-rates': 'PayrollMutate',
  flows: 'FlowView',
  'flow-builder': 'FlowView',
  'flow-builder-edit': 'FlowView',
  'flow-templates': 'FlowView',
  'log-work': null,
  'gym-settings': 'GymMutate',
  redirect: null,
};

const checkPermission = (key: string, hasPerm: (permission: string) => boolean): boolean => {
  const permission = key in routeKeyToPermissionMap ? routeKeyToPermissionMap[key] : null;
  if (!permission) return true;
  return hasPerm(permission);
};

const removeRoutesAndLinks = (routeMap: IRouteMap, hasPerm: (permission: string) => boolean): IRouteMap => {
  const trimmedRoutes = routeMap.routes.filter(route => {
    return checkPermission(route.key, hasPerm);
  });

  const trimmedNavLinks = routeMap.navLinks.reduce((accum, navLink) => {
    if (navLink.subLinks) {
      const subLinks = navLink.subLinks.reduce((acc, subLink) => {
        const allowed = checkPermission(subLink.key, hasPerm);
        if (allowed) acc.push(subLink);
        return acc;
      }, [] as INavLink[]);
      if (subLinks.length) accum.push({ ...navLink, subLinks });
    } else {
      const allowed = checkPermission(navLink.key, hasPerm);
      if (allowed) accum.push(navLink);
    }
    return accum;
  }, [] as INavLink[]);

  return {
    layout: routeMap.layout,
    routes: trimmedRoutes,
    navLinks: trimmedNavLinks,
  };
};

export const getStaffRouteMap = (
  role: 'owner' | 'staff',
  gym: SessionGym | null,
  hasPerm: (permission: string) => boolean,
  isDesktop: boolean,
): IRouteMap => {
  if (!gym) return loadingRouteMap;
  if (gym.stripeStatus === 'Onboard') {
    return onboardingRouteMap;
  }
  const desktopRouteMap = gym.stripeAccountType === 'custom' ? customConnectStaffRouteMap : staffRouteMap;
  let routeMap = isDesktop ? desktopRouteMap : staffMobileRouteMap;
  if (role === 'staff') {
    routeMap = removeRoutesAndLinks(routeMap, hasPerm);
  }
  return routeMap;
};
