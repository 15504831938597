import React from 'react';

import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import { useAuth } from 'context/auth-context';
import Error from 'pages/shared/Error';
import Loading from 'pages/shared/Loading';
import { getRouteMapList } from 'routes/index';
import Routes from 'routes/render-routes';

const Router = (): JSX.Element => {
  const theme = useMantineTheme();

  const { session, isLoading, hasPerm } = useAuth();

  const isKiosk = localStorage.getItem('isKiosk') === 'true';
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.sm}`, true);

  const routeMapList = getRouteMapList(session, isLoading, isKiosk, isDesktop, hasPerm);

  return (
    <Sentry.ErrorBoundary fallback={<Error />}>
      <Notifications
        data-cy='notificationsContainer'
        position={session?.role === 'athlete' ? 'top-center' : 'bottom-left'}
      />
      <React.Suspense fallback={<Loading />}>
        <Routes routeMapList={routeMapList} />
      </React.Suspense>
    </Sentry.ErrorBoundary>
  );
};

export default Router;
