import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ErrorMessage, KiloStaff } from 'types';
import { client } from 'utils/api-client';

const updateSelfKiloStaff = (staff: KiloStaff): Promise<AxiosResponse<KiloStaff>> => {
  return client.put(`kilo-staff/self/`, staff);
};

export default function useUpdateSelfKiloStaff(): UseMutationResult<
  AxiosResponse<KiloStaff>,
  AxiosError<ErrorMessage>,
  KiloStaff
> {
  const queryClient = useQueryClient();

  return useMutation(updateSelfKiloStaff, {
    onSuccess: () => {
      queryClient.invalidateQueries(['kiloStaff']);
    },
  });
}
