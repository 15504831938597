import { useQuery, UseQueryResult } from 'react-query';
import { GymRetrieve } from 'types';
import { client } from 'utils/api-client';

function useGym(gymId?: string | null): UseQueryResult<{ data: GymRetrieve }> {
  return useQuery(['gyms', gymId], () => client.get(`gyms/${gymId}/`), {
    enabled: !!gymId,
  });
}

export default useGym;
