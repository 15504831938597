import React from 'react';

import { Dropzone, DropzoneProps, FileRejection, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconX } from '@tabler/icons-react';
import notificationHandler from 'utils/notifications';

interface IFileUploadProps extends DropzoneProps {
  handleFileReject?: (files: FileRejection[]) => void;
  accept?: string[];
  disabled?: boolean;
  size: number;
}

const FileUpload = ({
  handleFileReject,
  accept = IMAGE_MIME_TYPE,
  disabled = false,
  children,
  size,
  ...dropzoneProps
}: IFileUploadProps): JSX.Element => {
  const { errorNotification } = notificationHandler();

  const handleReject = (files: FileRejection[]): void => {
    if (files[0].errors[0].code == 'file-too-large') {
      errorNotification('The selected file is too large. Please select a file smaller than 5MB.');
    } else {
      errorNotification(files[0].errors[0].message);
    }
  };

  return (
    <Dropzone
      accept={accept}
      onReject={handleFileReject || handleReject}
      multiple={false}
      maxFiles={1}
      disabled={disabled}
      {...dropzoneProps}
    >
      <Dropzone.Accept>
        <IconUpload color='green' size={size} />
      </Dropzone.Accept>
      <Dropzone.Reject>
        <IconX color='red' size={size} />
      </Dropzone.Reject>
      <Dropzone.Idle>{children}</Dropzone.Idle>
    </Dropzone>
  );
};

export default FileUpload;
