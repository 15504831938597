import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { ErrorMessage, SelectProfile } from 'types';
import { client } from 'utils/api-client';

const selectProfile = (payload: SelectProfile): Promise<AxiosResponse<string>> => {
  return client.post('/auth/select-profile/', payload);
};

export default function useSelectProfile(): UseMutationResult<
  AxiosResponse<string>,
  AxiosError<ErrorMessage>,
  SelectProfile
> {
  return useMutation(selectProfile);
}
