import PublicLayout from 'layouts/PublicLayout';
import ProfileManagement from 'pages/private/ProfileManagement/ProfileManagement';
import Loading from 'pages/shared/Loading';
import { Navigate } from 'react-router-dom';
import { Session } from 'types';

import { getAthleteRouteMap } from './athlete';
import { getKiloStaffRouteMap } from './kilo-staff';
import { getKioskRouteMap } from './kiosk';
import { getSalesPortalRouteMap } from './sales-portal';
import { getStaffRouteMap } from './staff';
import { IRouteMap } from './types';
import { getUnauthenticatedRouteMap } from './unauthenticated';

export const loadingRouteMap: IRouteMap = {
  layout: PublicLayout,
  routes: [
    {
      key: 'loading',
      component: Loading,
      path: '*',
    },
  ],
  navLinks: [],
};

export const profileSelectionRouteMap: IRouteMap = {
  layout: PublicLayout,
  routes: [
    {
      key: 'profile-selection',
      component: ProfileManagement,
      path: '/',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/' }),
      path: '*',
    },
  ],
  navLinks: [],
};

export const getRouteMapList = (
  session: Session | null,
  isLoading: boolean,
  isKiosk: boolean,
  isDesktop: boolean,
  hasPerm: (permission: string) => boolean,
): IRouteMap[] => {
  if (isLoading) return [loadingRouteMap];
  if (!session) return [getUnauthenticatedRouteMap(), getSalesPortalRouteMap()];
  if (isKiosk) return [getKioskRouteMap()];
  if (!session.profile) return [profileSelectionRouteMap];
  if (session.role === 'kilo_staff') return [getKiloStaffRouteMap(), getSalesPortalRouteMap()];
  if (session.role === 'owner' || session.role === 'staff')
    return [getStaffRouteMap(session.role, session.gym, hasPerm, isDesktop), getSalesPortalRouteMap()];
  if (session.role === 'athlete') return [getAthleteRouteMap(), getSalesPortalRouteMap()];
  return [];
};
