import { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { UrlParams } from 'types';
import { PaginatedEventList, PaginatedEventTypeList } from 'types/generated';
import { client } from 'utils/api-client';
import { encodeUrlParams } from 'utils/functions';

interface IUseEventsType {
  athlete?: string;
  startDate: string;
  endDate: string;
}

function useEvents({
  athlete,
  startDate,
  endDate,
}: IUseEventsType): UseQueryResult<AxiosResponse<PaginatedEventList>> {
  const fetchEvents = (): Promise<AxiosResponse<PaginatedEventTypeList>> => {
    const params: UrlParams = { limit: '100', offset: '0' };

    if (athlete) {
      params.athlete = athlete;
    }
    if (startDate) {
      params.start_date = startDate;
    }
    if (endDate) {
      params.end_date = endDate;
    }

    return client.get(`events/?${encodeUrlParams(params)}`);
  };

  return useQuery(['events', athlete, startDate, endDate], fetchEvents, {
    enabled: !!athlete,
  });
}

export default useEvents;
