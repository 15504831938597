import { useCallback } from 'react';

import { AxiosResponse } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { UrlParams, PaginatedGoogleCalendarIntegrationList } from 'types';
import { client } from 'utils/api-client';
import { encodeUrlParams, urlParamsFromUrl } from 'utils/functions';

interface QueryKeyType {
  pageParam?: UrlParams;
}

interface IUseGoogleCalendarIntegrations {
  user?: string;
  limit?: string;
  enabled?: boolean;
}

function useGoogleCalendarIntegrations({
  user,
  limit = '20',
  enabled = false,
}: IUseGoogleCalendarIntegrations): UseInfiniteQueryResult<
  AxiosResponse<PaginatedGoogleCalendarIntegrationList>
> {
  const fetchGoogleCalendarIntegrations = useCallback(
    ({
      pageParam = { limit, offset: '0' },
    }: QueryKeyType): Promise<AxiosResponse<PaginatedGoogleCalendarIntegrationList>> => {
      const params = pageParam;

      if (user !== undefined) {
        params.user = user;
      }
      return client.get(`google-calendar-integrations/?${encodeUrlParams(params)}`);
    },
    [user, limit],
  );

  return useInfiniteQuery(['googleCalendarIntegrations', user], fetchGoogleCalendarIntegrations, {
    getNextPageParam: page => {
      return page.data.next ? urlParamsFromUrl(page.data.next) : undefined;
    },
    enabled: enabled,
  });
}

export default useGoogleCalendarIntegrations;
