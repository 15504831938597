import { AxiosError, AxiosResponse } from 'axios';
import { client } from 'utils/api-client';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AthleteAppointment, AthleteAppointmentWrite, ErrorMessage } from 'types';

export default function useUpdateAthleteAppointment(): UseMutationResult<
  AxiosResponse<AthleteAppointment>,
  AxiosError<ErrorMessage>,
  AthleteAppointmentWrite
> {
  
  const queryClient = useQueryClient();

  const updateAthleteAppointment = (
    athleteAppointment: AthleteAppointmentWrite,
  ): Promise<AxiosResponse<AthleteAppointment>> => {
    return client.put(
      `athlete-appointments/${athleteAppointment.athleteAppointmentId}/`,
      athleteAppointment,
    );
  };

  return useMutation(updateAthleteAppointment, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['athleteEvents'],
      });
      queryClient.invalidateQueries({
        queryKey: ['athleteAppointments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['appointments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['events'],
      });
      queryClient.invalidateQueries({
        queryKey: ['totalAttended'],
      });
      queryClient.invalidateQueries({
        queryKey: ['totalReserved'],
      });
    },
  });
}
