import { useCallback } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { ErrorMessage } from 'types';
import { client } from 'utils/api-client';

export default function useUploadProfilePicture(): UseMutationResult<
  AxiosResponse<{ url: string }>,
  AxiosError<ErrorMessage>,
  { file: File; staffId: string }
> {
  const queryClient = useQueryClient();

  const uploadProfilePicture = useCallback(
    ({ staffId, file }: { staffId: string; file: File }): Promise<AxiosResponse<{ url: string }>> => {
      const formData = new FormData();
      formData.append('file', file);

      return client.post(`auth/users/${staffId}/profile_picture/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    [],
  );

  return useMutation(uploadProfilePicture, {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
    },
  });
}
