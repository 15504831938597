import { useCallback } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { ErrorMessage, GoogleCalendarIntegration, GoogleCalendarIntegrationCreate } from 'types';
import { client } from 'utils/api-client';

export default function useCreateGoogleCalendarIntegration(): UseMutationResult<
  AxiosResponse<GoogleCalendarIntegration>,
  AxiosError<ErrorMessage>,
  GoogleCalendarIntegrationCreate
> {
  const queryClient = useQueryClient();

  const createGoogleCalendarIntegration = useCallback(
    (
      googleCalendarIntegration: GoogleCalendarIntegrationCreate,
    ): Promise<AxiosResponse<GoogleCalendarIntegration>> => {
      return client.post(`google-calendar-integrations/`, googleCalendarIntegration);
    },
    [],
  );

  return useMutation(createGoogleCalendarIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries('googleCalendarIntegrations');
    },
  });
}
