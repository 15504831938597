import { useQuery, UseQueryResult } from 'react-query';
import { AthleteHasPastDueBills } from 'types';
import { client } from 'utils/api-client';

export default function useAthleteHasPastDueBills(
  athleteId: string | undefined,
): UseQueryResult<{ data: AthleteHasPastDueBills }> {
  return useQuery(
    ['athleteHasPastDueBills', athleteId],
    () => client.get(`athletes/${athleteId}/has-past-due-bills/`),
    {
      enabled: !!athleteId,
    },
  );
}
