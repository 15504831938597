import { useCallback } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { GoogleCalendarIntegration, GoogleCalendarIntegrationUpdate, ErrorMessage } from 'types';
import { client } from 'utils/api-client';

export default function useUpdateGoogleCalendarIntegration(): UseMutationResult<
  AxiosResponse<GoogleCalendarIntegration>,
  AxiosError<ErrorMessage>,
  GoogleCalendarIntegrationUpdate
> {
  const queryClient = useQueryClient();

  const updateGoogleCalendarIntegration = useCallback(
    (
      googleCalendarIntegration: GoogleCalendarIntegrationUpdate,
    ): Promise<AxiosResponse<GoogleCalendarIntegration>> => {
      return client.put(
        `google-calendar-integrations/${googleCalendarIntegration.googleCalendarIntegrationId}/`,
        googleCalendarIntegration,
      );
    },
    [],
  );

  return useMutation(updateGoogleCalendarIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries('googleCalendarIntegrations');
    },
  });
}
