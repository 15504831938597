import React from 'react';

import {
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Paper,
  px,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useShallowEffect } from '@mantine/hooks';
import { useGoogleLogin } from '@react-oauth/google';
import { IconBrandGoogle } from '@tabler/icons-react';
import useCreateGoogleCalendarIntegration from 'hooks/googleCalendarIntegration/useCreateGoogleCalendarIntegration';
import useGoogleCalendarIntegrations from 'hooks/googleCalendarIntegration/useGoogleCalendarIntegrations';
import useUpdateGoogleCalendarIntegration from 'hooks/googleCalendarIntegration/useUpdateGoogleCalendarIntegration';
import useSelfStaff from 'hooks/staff/useSelfStaff';
import notificationHandler from 'utils/notifications';

interface GoogleCalendarIntegrationFormValues {
  googleCalendarIntegrationId: string;
  syncAppointmentsToCalendar: boolean;
  syncClassesToCalendar: boolean;
  authorizationCode: string | null;
  email: string;
}

const GoogleCalendarIntegrationCard = (): JSX.Element => {
  const theme = useMantineTheme();

  const { data, isLoading } = useSelfStaff();
  const { data: googleCalendarIntegrationsData, isLoading: googleCalendarIntegrationsLoading } =
    useGoogleCalendarIntegrations({
      user: data?.data.staffId,
      enabled: data?.data.isCoach,
    });

  const googleCalendarIntegration =
    googleCalendarIntegrationsData && googleCalendarIntegrationsData.pages[0].data.results[0];

  const { successNotification, errorNotification } = notificationHandler();
  const createGoogleIntegrationMutation = useCreateGoogleCalendarIntegration();
  const updateGoogleIntegrationMutation = useUpdateGoogleCalendarIntegration();

  const form = useForm<GoogleCalendarIntegrationFormValues>({
    initialValues: {
      googleCalendarIntegrationId: '',
      syncAppointmentsToCalendar: false,
      syncClassesToCalendar: false,
      authorizationCode: null,
      email: '',
    },
  });

  const handleGoogleCalendarIntegrationCreate = (authorizationCode: string): void => {
    createGoogleIntegrationMutation.mutate(
      { authorizationCode: authorizationCode },
      {
        onError: error => {
          errorNotification('Integration update failed', error.response?.data.message);
        },
        onSuccess: () => {
          successNotification('Integration was successfully updated');
        },
      },
    );
  };

  const handleGoogleCalendarIntegrationUpdate = (payload: GoogleCalendarIntegrationFormValues): void => {
    updateGoogleIntegrationMutation.mutate(payload, {
      onError: error => {
        errorNotification('Integration update failed', error.response?.data.message);
      },
      onSuccess: () => {
        successNotification('Integration was successfully updated');
      },
    });
  };

  const handleGoogleLoginSuccess = (authorizationCode: string): void => {
    if (authorizationCode) {
      if (!googleCalendarIntegration) {
        handleGoogleCalendarIntegrationCreate(authorizationCode);
      } else {
        handleGoogleCalendarIntegrationUpdate({
          ...form.values,
          authorizationCode: authorizationCode,
        });
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: codeResponse => handleGoogleLoginSuccess(codeResponse.code),
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar.events',
  });

  useShallowEffect(() => {
    if (!!googleCalendarIntegration && !googleCalendarIntegrationsLoading) {
      form.setValues(googleCalendarIntegration);
      form.resetDirty();
    }
  }, [googleCalendarIntegration, googleCalendarIntegrationsLoading]);

  return (
    <Paper radius='lg' p='md' shadow='md' withBorder>
      <LoadingOverlay visible={isLoading} />
      <Stack>
        <Title order={4}>Google Calendar Integration</Title>

        {!googleCalendarIntegration ? (
          <Button onClick={login}>
            <IconBrandGoogle style={{ paddingRight: `calc(${px(theme.spacing.xs)}px)` }} />
            Connect Google Calendar
          </Button>
        ) : (
          <>
            <Group>
              <Text color='dimmed'>Connected to {googleCalendarIntegration.email}</Text>
              <Button onClick={login} variant='outline' compact>
                <IconBrandGoogle style={{ paddingRight: `calc(${px(theme.spacing.xs)}px)` }} />
                Change Connection
              </Button>
            </Group>
            <form
              id='google-calendar-integration-form'
              onSubmit={form.onSubmit(handleGoogleCalendarIntegrationUpdate)}
            >
              <Stack>
                <Checkbox
                  checked={form.values.syncAppointmentsToCalendar}
                  label='Synchronize appointments to Google Calendar'
                  disabled={updateGoogleIntegrationMutation.isLoading}
                  {...form.getInputProps('syncAppointmentsToCalendar')}
                />
                <Checkbox
                  checked={form.values.syncClassesToCalendar}
                  label='Synchronize classes to Google Calendar'
                  disabled={updateGoogleIntegrationMutation.isLoading}
                  {...form.getInputProps('syncClassesToCalendar')}
                />
                <Button
                  form='google-calendar-integration-form'
                  type='submit'
                  loading={updateGoogleIntegrationMutation.isLoading}
                  disabled={!form.isDirty()}
                >
                  Save
                </Button>
              </Stack>
            </form>
          </>
        )}
      </Stack>
    </Paper>
  );
};

export default GoogleCalendarIntegrationCard;
