import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { ErrorMessage, Login } from 'types';
import { client } from 'utils/api-client';

const login = (payload: Login): Promise<AxiosResponse<string>> => {
  return client.post('/auth/login/', payload);
};

export default function useLogin(): UseMutationResult<
  AxiosResponse<string>,
  AxiosError<ErrorMessage>,
  Login
> {
  return useMutation(login);
}
