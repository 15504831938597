import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { ErrorMessage } from 'types';
import { client } from 'utils/api-client';

const stopImpersonation = (): Promise<AxiosResponse<null>> => {
  return client.delete(`auth/stop-impersonation/`);
};

export default function useStopImpersonation(): UseMutationResult<
  AxiosResponse<null>,
  AxiosError<ErrorMessage>
> {
  return useMutation(stopImpersonation);
}
