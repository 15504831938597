import React from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import ThemeProvider from 'context/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import Router from 'Router';

import config from './config';
import AuthProvider from './context/auth-context';

const queryClient = new QueryClient();

const App = (): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <BrowserRouter>
          <AuthProvider>
            <GoogleOAuthProvider clientId={config.GOOGLEID || process.env.GOOGLEID || ''}>
              <Router />
            </GoogleOAuthProvider>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>

      {/* {config.SHOW_RQ_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} position='bottom-left' />} */}
    </QueryClientProvider>
  );
};

export default App;
