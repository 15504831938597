import React from 'react';

import {
  IconArchive,
  IconCalendar,
  IconCalendarCheck,
  IconFileInvoice,
  IconUserCircle,
} from '@tabler/icons-react';
import MobileLayout from 'layouts/MobileLayout';
import { Navigate } from 'react-router-dom';

import { IRouteMap } from './types';

export const athleteRouteMap: IRouteMap = {
  layout: MobileLayout,
  routes: [
    {
      key: 'schedule',
      component: React.lazy(() => import('pages/private/athlete/Schedule')),
      path: '/',
    },
    {
      key: 'packages',
      component: React.lazy(() => import('pages/private/athlete/Packages')),
      path: '/packages',
    },
    {
      key: 'events',
      component: React.lazy(() => import('pages/private/athlete/Events')),
      path: '/events',
    },
    {
      key: 'class',
      component: React.lazy(() => import('pages/private/athlete/EventDetail')),
      path: '/classes/:eventId',
    },
    {
      key: 'appointment',
      component: React.lazy(() => import('pages/private/athlete/EventDetail')),
      path: '/appointments/:eventId',
    },
    {
      key: 'documents',
      component: React.lazy(() => import('pages/private/athlete/Documents')),
      path: '/documents',
    },
    {
      key: 'document',
      component: React.lazy(() => import('pages/private/athlete/DocumentDetail')),
      path: '/documents/:documentId',
    },
    {
      key: 'account',
      component: React.lazy(() => import('pages/private/athlete/Account')),
      path: '/account',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/' }),
      path: '*',
    },
  ],
  navLinks: [
    {
      key: 'packages',
      Icon: IconArchive,
      label: 'Packages',
      route: '/packages',
    },
    {
      key: 'events',
      Icon: IconCalendarCheck,
      label: 'My Events',
      route: '/events',
    },
    {
      key: 'schedule',
      Icon: IconCalendar,
      label: 'Schedule',
      route: '/',
    },
    {
      key: 'documents',
      Icon: IconFileInvoice,
      label: 'Documents',
      route: '/documents',
    },
    {
      key: 'account',
      Icon: IconUserCircle,
      label: 'Account',
      route: '/account',
    },
  ],
};

export const getAthleteRouteMap = (): IRouteMap => {
  return athleteRouteMap;
};
