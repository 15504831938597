import React from 'react';

import { Avatar, Button, Container, Flex, Group, Text } from '@mantine/core';
import { useFullscreen } from '@mantine/hooks';
import { useAuth } from 'context/auth-context';
import useGym from 'hooks/gym/useGym';
import { Outlet } from 'react-router-dom';

const KioskLayout = (): JSX.Element => {
  const { fullscreen } = useFullscreen();
  const { logout, session } = useAuth();
  const { data } = useGym(session?.gym?.gymId);

  const handleLogout = (): void => {
    localStorage.removeItem('isKiosk');
    logout();
  };

  return (
    <>
      {!fullscreen && (
        <Container py='xl' w='100%'>
          <Group position='apart' data-cy='gymNavigationPanel'>
            <Group align='center'>
              {data?.data && (
                <Avatar radius='xl' variant='outline' color='kiloBlue' src={data.data.profilePicture}>
                  {data.data.title[0].toUpperCase()}
                </Avatar>
              )}
              <Flex direction='column' gap={0}>
                <Text weight={600} color='dark'>
                  {data?.data.title}
                </Text>
                <Text color='dimmed'>{data?.data.email}</Text>
              </Flex>
            </Group>
            <Button color='red' onClick={handleLogout}>
              Logout
            </Button>
          </Group>
        </Container>
      )}
      <Outlet />
    </>
  );
};

export default KioskLayout;
